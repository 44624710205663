// EXTERNAL MODULES
import React, { useState } from "react"

// INTERNAL MODULES
import hands from "../db/hands"
import classes from "./index.module.css"

export default () => {
  const [selectedHand, setSelectedHand] = useState(-1)

  const suit = {
    hearts: String.fromCharCode(9829),
    spades: String.fromCharCode(9824),
    clubs: String.fromCharCode(9827),
    diamonds: String.fromCharCode(9830),
  }

  const selectHand = index => {
    if (selectedHand === index) return setSelectedHand(-1)

    setSelectedHand(index)
  }

  const baseClasses = suit => {
    const { card, red, black } = classes

    if (suit === "hearts" || suit === "diamonds") {
      return [card, red].join(" ")
    } else {
      return [card, black].join(" ")
    }
  }

  return (
    <main className={classes.container}>
      <p>
        Texas Holdem hands ranked from strongest to weakest, starting with the
        best possible hand, the Royal Flush.
      </p>
      <p>
        For more information, <strong>click</strong> on a hand.
      </p>
      {hands.map(({ name, info, cards }, index) => (
        <div
          className={classes.handContainer}
          onClick={() => selectHand(index)}
          onKeyDown={() => selectHand(index)}
          key={name + index}
          role="presentation"
        >
          <h2 className={classes.handContainer__title}>
            #{index + 1}. {name}
          </h2>

          <div className={classes.hand}>
            {cards.map((card, index) => (
              <div className={baseClasses(card.suit)} key={index}>
                <div className={classes.card__value}>
                  {card.value.toUpperCase()}
                </div>

                <div className={classes.card__suit}>{suit[card.suit]}</div>
              </div>
            ))}
          </div>

          {selectedHand === index && (
            <p className={classes.handContainer__info}>{info}</p>
          )}
        </div>
      ))}

      <div className={classes.siteInfo}>Designed and built by Simon Ward</div>
    </main>
  )
}
