const hands = [
  {
    name: "Royal Flush",
    info: `Five cards of the highest possible value, in sequence, and of the same suit. The best possible hand.`,
    cards: [
      {
        suit: "hearts",
        value: "10",
      },
      {
        suit: "hearts",
        value: "j",
      },
      {
        suit: "hearts",
        value: "q",
      },
      {
        suit: "hearts",
        value: "k",
      },
      {
        suit: "hearts",
        value: "a",
      },
    ],
  },
  {
    name: "Straight Flush",
    info: `Five cards in sequence, and of the same suit.`,
    cards: [
      {
        suit: "spades",
        value: "5",
      },
      {
        suit: "spades",
        value: "6",
      },
      {
        suit: "spades",
        value: "7",
      },
      {
        suit: "spades",
        value: "8",
      },
      {
        suit: "spades",
        value: "9",
      },
    ],
  },
  {
    name: "Four Of A Kind",
    info: `Four cards of one rank, and any other unmatched card.`,
    cards: [
      {
        suit: "hearts",
        value: "a",
      },
      {
        suit: "clubs",
        value: "a",
      },
      {
        suit: "diamonds",
        value: "a",
      },
      {
        suit: "spades",
        value: "a",
      },
      {
        suit: "hearts",
        value: "2",
      },
    ],
  },
  {
    name: "Full House",
    info: `Three matching cards of one rank, and two matching cards of another rank.`,
    cards: [
      {
        suit: "spades",
        value: "a",
      },
      {
        suit: "diamonds",
        value: "a",
      },
      {
        suit: "clubs",
        value: "a",
      },
      {
        suit: "hearts",
        value: "k",
      },
      {
        suit: "spades",
        value: "k",
      },
    ],
  },
  {
    name: "Flush",
    info: `Five cards of the same suit, but not in sequence. If two players have a flush, the player with the highest card in the flush wins.`,
    cards: [
      {
        suit: "hearts",
        value: "2",
      },
      {
        suit: "hearts",
        value: "4",
      },
      {
        suit: "hearts",
        value: "6",
      },
      {
        suit: "hearts",
        value: "8",
      },
      {
        suit: "hearts",
        value: "k",
      },
    ],
  },
  {
    name: "Straight",
    info: `Five cards of sequential rank in at least two different suits. Aces can be used to follow a king, or start a straight followed by a two.`,
    cards: [
      {
        suit: "hearts",
        value: "5",
      },
      {
        suit: "clubs",
        value: "6",
      },
      {
        suit: "diamonds",
        value: "7",
      },
      {
        suit: "spades",
        value: "8",
      },
      {
        suit: "hearts",
        value: "9",
      },
    ],
  },
  {
    name: "Three Of A Kind",
    info: `Three cards of the same rank, with two other unmatched cards. The hand is completed with the two highest cards that are available.`,
    cards: [
      {
        suit: "clubs",
        value: "a",
      },
      {
        suit: "hearts",
        value: "a",
      },
      {
        suit: "spades",
        value: "a",
      },
      {
        suit: "diamonds",
        value: "2",
      },
      {
        suit: "clubs",
        value: "7",
      },
    ],
  },
  {
    name: "Two Pair",
    info: `Two cards of one rank, plus two cards of another one rank. The last card to complete the hand is the highest card that is left available.`,
    cards: [
      {
        suit: "diamonds",
        value: "k",
      },
      {
        suit: "clubs",
        value: "k",
      },
      {
        suit: "hearts",
        value: "q",
      },
      {
        suit: "spades",
        value: "q",
      },
      {
        suit: "diamonds",
        value: "j",
      },
    ],
  },
  {
    name: "Pair",
    info: `Two cards of one rank. The hand is filled up with the three highest cards that are left available.`,
    cards: [
      {
        suit: "clubs",
        value: "a",
      },
      {
        suit: "hearts",
        value: "a",
      },
      {
        suit: "spades",
        value: "9",
      },
      {
        suit: "diamonds",
        value: "8",
      },
      {
        suit: "clubs",
        value: "7",
      },
    ],
  },
  {
    name: "High Card",
    info: `Five cards that don't meet any of the above requirements.`,
    cards: [
      {
        suit: "hearts",
        value: "a",
      },
      {
        suit: "spades",
        value: "8",
      },
      {
        suit: "diamonds",
        value: "6",
      },
      {
        suit: "clubs",
        value: "4",
      },
      {
        suit: "hearts",
        value: "2",
      },
    ],
  },
]

export default hands
